@keyframes moveBackground {
    0% {
      background-position: center;
    }
    50% {
      background-position: top center;
    }
    100% {
      background-position: left;
    }
  }
  
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    overflow-y: auto; /* Asegura que siempre haya un scrollbar visible */
  }
  
  .home {
    position: relative;
    width: 100%;
  }
  
  .background {
    background-image: url('../assets/tww.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    animation: moveBackground 10s infinite;
  }
  
  .home::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7); /* Ajusta la transparencia aquí */
    z-index: -1;
  }
  
  .content {
    text-align: center;
    color: black;
    z-index: 2;
    position: relative;
    min-height: 100vh; /* Asegura que ocupa toda la pantalla */
    padding: 150px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .logo-image {
    width: 200px; /* Ajusta el tamaño del logo según sea necesario */
    margin-bottom: 0px;
  }
  
  .logo {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .tale {
    color: rgb(255, 255, 255); /* Ajusta este valor según sea necesario */
  }
  
  .weaver {
    color: rgb(255, 255, 255); /* Ajusta este valor según sea necesario */
  }
  
  .scroll-content {
    background-image: url('../assets/scroll-background.png');
    background-size: cover;
    background-repeat: repeat;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .section {
    text-align: center;
    font-size: 2rem;
    width: 100%;
    margin-top: 100px; /* Margen superior por defecto */
    margin-bottom: 100px; /* Margen inferior por defecto */
  }
  
  @media (max-width: 1200px) {
    .section {
      margin-top: 80px; /* Reducir margen en pantallas medianas */
      margin-bottom: 80px;
    }
  }
  
  @media (max-width: 992px) {
    .section {
      margin-top: 60px; /* Reducir margen en pantallas más pequeñas */
      margin-bottom: 60px;
    }
  }
  
  @media (max-width: 768px) {
    .section {
      margin-top: 40px; /* Reducir margen en pantallas aún más pequeñas */
      margin-bottom: 40px;
    }
  }
  
  @media (max-width: 576px) {
    .section {
      margin-top: 20px; /* Reducir margen al mínimo en pantallas muy pequeñas */
      margin-bottom: 20px;
    }
  }
  

  @media (max-width: 1200px) {
    .section h2 {
      font-size: 2 rem;
    }
  }
  
  @media (max-width: 992px) {
    .section h2 {
      font-size: 1.75rem;
    }
  }
  
  @media (max-width: 768px) {
    .section h2 {
      font-size: 1.5rem;
    }
  }
  
  @media (max-width: 576px) {
    .section h2 {
      font-size: 1.5rem;
    }
  }
  
  .section h2 {
    margin-bottom: 30px;
  }
  
  .carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
  }
  
  .carousel-container {
    width: 100%; /* Ajusta el tamaño del contenedor del carrusel */
    position: relative;
    padding-top: 51.47%; /* Aspect ratio de 2048/1053 */
    overflow: hidden; /* Asegura que las imágenes no se salgan del contenedor */
  }
  
  .story-image {
    position: absolute;
    top: 0;
    left: 50%; /* Centra horizontalmente */
    transform: translateX(-50%);
    width: 80%;
    height: 80%;
    object-fit: cover; /* Asegura que las imágenes mantengan el aspecto */
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .story-image.active {
    opacity: 1;
  }
  
  .arrow {
    width: 50px; /* Ajusta el tamaño de las flechas */
    height: 50px;
    cursor: pointer;
    user-select: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
  }
  
  .arrow.left {
    left: 0;
    margin-left: 70px; /* Ajusta la distancia desde el borde izquierdo */
  }
  
  .arrow.right {
    right: 0;
    margin-right: 80px; /* Ajusta la distancia desde el borde derecho */
  }
  
  .arrow:hover {
    opacity: 0.7;
  }
  
  .section-title {
    margin-bottom: 10px;
    font-size: 3rem;
    padding-top: -50px;
  }
  
  .video-container {
    position: relative;
    width: 80%; /* Ajusta el tamaño del contenedor del video según sea necesario */
    padding-top: 40.25%; /* Relación de aspecto 16:9 */
    margin: 20px auto; /* Ajusta este valor para centrar el contenedor */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #youtubeVideo, #youtubeTrailer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  #nftSection {
    padding: 20px 0; /* Ajusta el padding superior e inferior */
  }
  
  .nft-steps-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; /* Permite que los elementos se ajusten en pantallas más pequeñas */
  }
  
  .nft-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px; /* Ajusta el margen entre los pasos */
    width: 33%; /* Ajusta el ancho de cada paso para que sean dinámicos */
    max-width: 700px; /* Máximo ancho de las imágenes */
  }
  
  .nft-image {
    width: 100%; /* Asegura que las imágenes ocupen todo el ancho del contenedor */
    height: auto; /* Mantiene la proporción de las imágenes */
    margin-bottom: 10px;
  }
  
  @media (max-width: 1200px) {
    .nft-step {
      width: 45%;
    }
    .nft-image {
      width: 90%; /* Asegura que las imágenes ocupen un tamaño adecuado */
    }
  }
  
  @media (max-width: 992px) {
    .nft-step {
      width: 60%;
    }
    .nft-image {
      width: 85%; /* Asegura que las imágenes ocupen un tamaño adecuado */
    }
  }
  
  @media (max-width: 768px) {
    .nft-step {
      width: 80%;
    }
    .nft-image {
      width: 80%; /* Asegura que las imágenes ocupen un tamaño adecuado */
    }
  }
  
  @media (max-width: 576px) {
    .nft-step {
      width: 100%; /* Cada paso ocupa el 100% del ancho en pantallas muy pequeñas */
    }
    .nft-image {
      width: 75%; /* Asegura que las imágenes ocupen un tamaño adecuado */
    }
  }
  
  .nft-step p {
    margin: 0;
    font-size: 1.5rem;
    color: rgb(0, 0, 0);
    font-weight: bold; /* Aplica negrita a los textos */
  }


  .icon-container {
    position: absolute;
    top: 30px;
    display: flex;
    gap: 20px; /* Espacio entre los iconos */
  }
  
  @media (max-width: 1200px) {
    .nft-step p {
      font-size: 1.4rem;
    }
  }
  
  @media (max-width: 992px) {
    .nft-step p {
      font-size: 1.3rem;
    }
  }
  
  @media (max-width: 768px) {
    .nft-step p {
      font-size: 1.2rem;
    }
  }
  
  @media (max-width: 576px) {
    .nft-step p {
      font-size: 1rem;
    }
  }
  
  .social-icon {
    width: 50px; /* Ajusta el tamaño de los iconos según sea necesario */
    height: 45px;
    cursor: pointer;
  }

  .icon-container.left {
    left: 55px;
  }
  
  .icon-container.right {
    right: 55px;
  }
  